import rpc from "@/rpc";

class FunctionsService {
    rpc

    constructor(rpc) {
        this.rpc = rpc
    }

    getBorrower(uuid) {
        return this.rpc.execute('getBorrower', {
            uuid: uuid
        })
    }

    getApplication(uuid) {
        return this.rpc.execute('getApplication', {
            uuid
        });
    }

    getDeleteData(uuid, sendStopCheck) {
        return this.rpc.execute('getDeleteData', {
            applicationUuid: uuid,
            sendStopCheck
        })
    }

    deleteEvent(uuid, code, useOldFormat) {
        return this.rpc.execute('deleteEvent', {
            applicationUuid: typeof uuid === 'string' ? uuid.trim() : null,
            code,
            useOldFormat
        })
    }
}

export default new FunctionsService(rpc)